@font-face {
	font-family: 'Chevalier Becker Stripes Caps';
	src: url('/fonts/ChevalierBeckerStripesCaps.woff2') format('woff2'),
	url('/fonts/ChevalierBeckerStripesCaps.woff') format('woff') {}
	font-weight: normal;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Latin Extra Condensed';
	src: url('/fonts/LatinExtraCondensedBT-Regular.woff2') format('woff2'),
	url('/fonts/LatinExtraCondensedBT-Regular.woff') format('woff') {}
	font-weight: normal;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Gauthier FY';
	src: url('/fonts/GauthierFY-MediumItalic.woff2') format('woff2'),
	url('/fonts/GauthierFY-MediumItalic.woff') format('woff') {}
	font-weight: 500;
	font-style: italic;
	font-display: swap; }

@font-face {
	font-family: 'Taberna Serif';
	src: url('/fonts/TabernaSerif-Regular.woff2') format('woff2'),
	url('/fonts/TabernaSerif-Regular.woff') format('woff') {}
	font-weight: normal;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Gauthier FY';
	src: url('/fonts/GauthierFY-BoldItalic.woff2') format('woff2'),
	url('/fonts/GauthierFY-BoldItalic.woff') format('woff') {}
	font-weight: bold;
	font-style: italic;
	font-display: swap; }

@font-face {
	font-family: 'Gauthier FY';
	src: url('/fonts/GauthierFY-Italic.woff2') format('woff2'),
	url('/fonts/GauthierFY-Italic.woff') format('woff') {}
	font-weight: normal;
	font-style: italic;
	font-display: swap; }

@font-face {
	font-family: 'Taberna Serif In';
	src: url('/fonts/TabernaSerif-RegularIn.woff2') format('woff2'),
	url('/fonts/TabernaSerif-RegularIn.woff') format('woff') {}
	font-weight: normal;
	font-style: normal;
	font-display: swap; }

@font-face {
	font-family: 'Bebas Neue';
	src: url('/fonts/BebasNeue.woff2') format('woff2'),
	url('/fonts/BebasNeue.woff') format('woff') {}
	font-weight: normal;
	font-style: normal;
	font-display: swap; }
