// you need this var for font-awesome
$fa-font-path: "~@fortawesome/fontawesome-pro/webfonts";
@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
// @import ~@fortawesome/fontawesome-pro/scss/regular
@import "~@fortawesome/fontawesome-pro/scss/solid";
// @import ~@fortawesome/fontawesome-pro/scss/duotone
// Uncomment the above if you need to use different fonts

// @import ~flickity/dist/flickity.css
@import "~swiper/swiper-bundle.css";

@import "fonts";
@import "tailwind";
